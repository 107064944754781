import React from 'react';
import { Grid, CardMedia, Link } from '@mui/material';
import { PageHelmet } from './PageHelmet';
import { GridContainer } from './GeotabGridComponents';
import { useAtom } from 'jotai';
import { selectedLanguageAtom } from '@atoms/appSettings';
import { useTranslation } from '@stateManagement/TranslationContext';
import { getTranslation } from '@helpers/languageTranslations';
import { getLanguageUrlPrefixByCode } from '@helpers/languages';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { PublicSearchBar } from '@components/PublicSearch/PublicSearchBar';
import { linkStyles, breakpoints, v2Colors } from '@web-for-marketing/react-core';
import { Heading, Text } from '@web-for-marketing/react-ui';
import notFound from '@images/404_Image.svg';

const classes = {
    fullContainer: {
        maxWidth: 1150,
        margin: '0 auto',
        padding: '0px 0px',
    },
    center: {
        textAlign: 'center',
    },
    minorSpacing: {
        marginBottom: '3rem',

        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: '2rem',
        },
    },
    description: {
        fontWeight: 700,
        color: v2Colors.core.blackTarr,
    },
    image: {
        marginBottom: '-10rem',
        maxWidth: '1100px',

        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: '-5rem',
        },
    },
    searchContainer: {
        maxWidth: '60rem !important',
        width: '100%',
    },
    homeLink: linkStyles.endOfLine,
} as const;

export function ErrorPage(): JSX.Element {
    const [selectedLanguage] = useAtom(selectedLanguageAtom);
    const { prefixStringWithBaseUrl } = useStaticValues();
    const { selectedLanguageTranslation } = useTranslation();
    const errorPageTranslation = getTranslation(selectedLanguageTranslation, 'notFound');

    return (
        <>
            <PageHelmet seo={{ title: errorPageTranslation.title }} />
            <GridContainer>
                <Grid container css={classes.center} alignItems='center' justifyContent='center' direction='column'>
                    <Grid item css={classes.minorSpacing}>
                        <Heading variant='h1' color='primary'>
                            {errorPageTranslation.heading}
                        </Heading>
                    </Grid>
                    <Grid item css={classes.minorSpacing}>
                        <Text variant='body1' css={classes.description}>
                            {errorPageTranslation.description}
                        </Text>
                        <Text variant='body1' css={classes.description}>
                            {errorPageTranslation.description2}
                            <Link href={getLanguageUrlPrefixByCode(selectedLanguage)} css={classes.homeLink}>
                                {errorPageTranslation.homepageLink}
                            </Link>
                            .
                        </Text>
                    </Grid>
                    <Grid item css={classes.searchContainer}>
                        <PublicSearchBar />
                    </Grid>
                </Grid>
                <Grid container alignItems='center' justifyContent='center' direction='column'>
                    <CardMedia
                        image={prefixStringWithBaseUrl(notFound)}
                        alt='An illustration of a blue person holding a telescope looking out into the distance on top of a mountain.'
                        component='img'
                        css={classes.image}
                    />
                </Grid>
            </GridContainer>
        </>
    );
}
