import { v2Colors, breakpoints } from '@web-for-marketing/react-core';

export const chatWidgetStyle = {
    chatIcon: {
        position: 'fixed',
        bottom: '3rem',
        right: '3rem',
        zIndex: 800,
        fontSize: '1.4rem !important',
        padding: '1.4rem !important',
        width: '18rem !important',
        height: '4rem !important',
        minHeight: '4rem !important',
        borderRadius: '0.4rem',
        justifyContent: 'left',
        backgroundColor: v2Colors.core.geotab,
        borderColor: v2Colors.core.geotab,
        '& svg': {
            marginLeft: '0 !important',
        },
        '&:hover svg': {
            fill: v2Colors.core.innovation,
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            bottom: '8rem',
            right: '1rem',
        },
    },
    chatCloseIcon: {
        right: '1.4rem',
        position: 'absolute',
        top: '1.1rem',
    },
} as const;
