import React from 'react';
import { Grid } from '@mui/material';
import { Heading, Text } from '@web-for-marketing/react-ui';
import { GridContainer } from './GeotabGridComponents';
import { useAdminRoute } from '@hooks/useAdminRoute';

type PrivateRouteProps = {
    permission: string;
    minimumAccessLevel?: number;
    children: JSX.Element;
};

export function PrivateRoute({
    permission,
    minimumAccessLevel = 100,
    children,
}: PrivateRouteProps): JSX.Element | null {
    const hasRouteAccess = useAdminRoute(permission, minimumAccessLevel);

    return hasRouteAccess ? (
        children
    ) : (
        <GridContainer>
            <Grid container alignItems='center' justifyContent='center' direction='column' gap={2}>
                <Grid item>
                    <Heading variant='h1'>Oh no!</Heading>
                </Grid>
                <Grid item>
                    <Text>You do not have permission to access this page.</Text>
                </Grid>
            </Grid>
        </GridContainer>
    );
}
