import { rolePermissions, accessLevels } from '@helpers/authorization';

export function hasPermission(userRoles = [], permission = '', accessLevel = 100) {
    return userRoles.some(
        (role) => rolePermissions[role] && getUserRolePermissionAccessLevel(role, permission) >= accessLevel
    );
}

export const hasRole = (allUserRoles = [], requiredUserRole = '') => {
    return allUserRoles.some((role) => role === requiredUserRole);
};

function getUserRolePermissionAccessLevel(userRole, permission) {
    return Number.isFinite(rolePermissions[userRole][permission])
        ? rolePermissions[userRole][permission]
        : accessLevels.none;
}
