import { useEffect, useRef } from 'react';
import { useAtom } from 'jotai';
import { isMobileAtom } from '@atoms/appSettings';
import { breakpoints } from '@web-for-marketing/react-core';

export function CheckMobile(): null {
    const resizeTimer = useRef<ReturnType<typeof setTimeout>>();
    const [isMobile, setMobile] = useAtom(isMobileAtom);
    const checkWindowSize = (windowObject: Window): void => {
        if (windowObject.innerWidth) {
            if (windowObject.innerWidth <= breakpoints.md) {
                if (!isMobile) {
                    setMobile(true);
                }
            } else {
                if (isMobile) {
                    setMobile(false);
                }
            }
        }
    };

    useEffect(() => {
        checkWindowSize(window);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleResize = (): void => {
            if (resizeTimer.current) clearTimeout(resizeTimer.current);

            resizeTimer.current = setTimeout(() => {
                checkWindowSize(window);
            }, 300);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            if (resizeTimer.current) clearTimeout(resizeTimer.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    return null;
}
