/* eslint-disable camelcase */
const key = 'geotabSource';
const secondsInADay = 86400;
const socialMediaSources = ['facebook', 'instagram', 'linkedin', 'tiktok', 'twitter', 'youtube'];
const organicSources = ['google', 'bing', 'yahoo', 'duckduckgo', 'baidu', 'ask'];

interface GeotabSource {
    initialDate: Date;
    intialUrl: string;
    initialReferrerUrl: string;
    intialSource: string;
    locale: string;
    utm_source: string;
    utm_medium: string;
    utm_campaign: string;
    visitorType: string;
    conversionUrl: string;
    conversionDate: Date;
    conversionSource: string;
    conversionReferrerUrl: string;
    expiryDate: Date;
}

export function initializeGeotabSourceCookie(daysToExpire = 30): void {
    if (!geotabSourceCookieExists()) {
        const secondsToExpire = daysToExpire * secondsInADay;
        const currentDate = new Date();
        const expiryDate = new Date(currentDate.getTime() + secondsToExpire * 1000);

        const geotabSource = {
            initialDate: currentDate,
            initialUrl: window.location.href,
            initialReferrerUrl: document.referrer,
            initialSource: getReferrerSource(),
            locale: navigator.language,
            ...getUtmQueryStringValues(),
            visitorType: 'prospect',
            conversionUrl: '',
            conversionDate: '',
            conversionSource: '',
            conversionReferrerUrl: '',
            expiryDate: expiryDate,
        };

        document.cookie = `${key}=${JSON.stringify(
            geotabSource
        )}; max-age=${secondsToExpire}; path=/; Secure; SameSite=Strict`;
    }
}

export function geotabSourceCookieExists(): boolean {
    return document.cookie.includes('geotabSource');
}

export function setCookieConversionData(): void {
    const geotabSource = getGeotabSourceCookieValue();

    if (geotabSource) {
        const currentDate = new Date();
        const secondsToExpire = Math.floor((geotabSource.expiryDate.getTime() - currentDate.getTime()) / 1000);

        geotabSource.conversionUrl = window.location.href;
        geotabSource.conversionDate = currentDate;
        geotabSource.conversionSource = getReferrerSource();
        geotabSource.conversionReferrerUrl = document.referrer;

        document.cookie = `${key}=${JSON.stringify(
            geotabSource
        )}; max-age=${secondsToExpire}; path=/; Secure; SameSite=Strict`;
    }
}

export function getGeotabSourceCookieValue(): GeotabSource | undefined {
    const geotabSourceCookie = document.cookie.split(';').find((c) => c.includes(key));
    return geotabSourceCookie ? getCookieValue(geotabSourceCookie) : undefined;
}

function getReferrerSource(): string {
    const referrerUrl = document.referrer.toLowerCase();

    if (includesAny(referrerUrl, socialMediaSources)) {
        return 'social';
    } else if (includesAny(referrerUrl, organicSources)) {
        return 'organic';
    } else {
        return 'direct';
    }
}

function includesAny(str: string, substrings: string[]): boolean {
    return substrings.some((substring) => str.includes(substring));
}

function getCookieValue(cookie: string): GeotabSource {
    const value = JSON.parse(cookie.split(/=(.*)/s)[1], jsonDateParser);
    return value;
}

function jsonDateParser(key: string, value: string): Date | string {
    return key === 'expiryDate' || key === 'initialDate' || key === 'conversionDate' ? new Date(value) : value;
}

function getUtmQueryStringValues(): Record<string, string> {
    const query = new URLSearchParams(window.location.search);
    const utm = {
        utm_source: query.get('utm_source') ?? '',
        utm_medium: query.get('utm_medium') ?? '',
        utm_campaign: query.get('utm_campaign') ?? '',
    };

    return utm;
}
