import { v2Colors } from '@web-for-marketing/react-core';

export const languageListStyles = {
    title: {
        paddingBottom: '1.5rem',
        fontWeight: 'bold',
    },
    languageLink: {
        paddingBottom: '1.5rem',
        color: v2Colors.core.innovation,
        display: 'flex',
        outline: 'none',
        width: 'fit-content',
        border: '1px solid rgba(255,255,255,0)',
        alignItems: 'center',
        borderRadius: '0.8rem',
        transition: 'background-color 300ms ease',
        '&:hover': {
            background: v2Colors.core.cloud,
        },
        '&:focus': {
            border: '1px solid #0059B3',
            boxSizing: 'border-box',
            background: 'white',
        },
    },
} as const;
