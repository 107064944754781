import { regexErrorMessages, regexPatterns } from './regexHelper';
import {
    currentRole,
    relativeLocation,
    authorPublicationsURL,
    accordionBlock,
    authorAccomplishments,
    authorEducation,
    authorExpertise,
    authorImageAlt,
    authorImage,
    authorSocial,
    authorBiography,
    authorShortBiography,
    blogEntityName,
    articleIndexStatus,
    articlePublishedStatus,
    articleCanonical,
    articleSlug,
    articleLanguage,
    entityRedirectUrl,
    columnLinkText,
    slugEntityBreadcrumbDisplayName,
} from '@helpers/AccessibleFormHelperConstants';
import { BlogFormHelper } from '@models/blogForm';
import { Author } from '@models/author';
import { regexMatchesOrIsEmpty, hasLength } from '@helpers/validators';
import { EntityName } from '@models/entity';
import { breadcrumbDisplayNameValidator } from './validationRules';

const friendlyName = 'Author';

export const authorFormHelper: BlogFormHelper = {
    name: 'Author',
    entityName: EntityName.Author,
    apiControllerName: 'author',
    gallerySelection: 'authorImage',
    hasMediaField: false,
    fields: [
        {
            id: 'author-language',
            key: 'language',
            name: 'Language',
            required: true,
            type: 'select',
            list: 'languages',
            listItemValueKey: 'id',
            readOnlyModes: ['edit'],
            hiddenLabel: true,
            formHelper: articleLanguage(friendlyName),
        },
        {
            id: 'author-name',
            key: 'name',
            name: 'Author Name',
            required: true,
            maxLength: 100,
            type: 'text',
            placeholderText: 'Enter the author\'s full name...',
            formHelper: blogEntityName(friendlyName),
        },
        {
            id: 'author-slug',
            key: 'slug',
            name: 'Url Slug',
            validators: [regexMatchesOrIsEmpty(regexPatterns.slug, regexErrorMessages.slug)],
            required: true,
            maxLength: 100,
            type: 'text',
            sanitizationConfiguration: {
                autoTrim: true,
                lowercaseOnChange: true,
            },
            formHelper: articleSlug(friendlyName),
        },
        {
            id: 'author-breadcrumbDisplayName',
            key: 'breadcrumbDisplayName',
            name: 'Breadcrumb Display Name',
            validators: [breadcrumbDisplayNameValidator],
            required: false,
            type: 'text',
            formHelper: slugEntityBreadcrumbDisplayName(friendlyName),
        },
        {
            id: 'author-canonical',
            key: 'canonical',
            name: 'Canonical Url',
            required: false,
            type: 'text',
            sanitizationConfiguration: {
                autoTrim: true,
            },
            formHelper: articleCanonical(friendlyName),
        },
        {
            id: 'author-image',
            key: 'image',
            name: 'Author Image',
            required: false,
            type: 'galleryImage',
            gallerySelection: 'authorImage',
            ratio: ['1:1'],
            maxWidth: 640,
            formHelper: authorImage,
            fieldsToRevalidate: ['author-imageAlt'],
            fieldKeyToUpdate: 'imageAlt',
        },
        {
            id: 'author-imageAlt',
            key: 'imageAlt',
            name: 'Author Image Alt',
            requiredCondition: (entity) => !!entity.image,
            maxLength: 300,
            type: 'text',
            formHelper: authorImageAlt,
        },
        {
            id: 'author-redirectUrl',
            key: 'redirectUrl',
            name: 'Redirect Url',
            required: false,
            maxLength: 5000,
            type: 'text',
            sanitizationConfiguration: {
                autoTrim: true,
            },
            formHelper: entityRedirectUrl(friendlyName),
        },
        {
            id: 'author-shortBio',
            key: 'shortBiography',
            name: 'Short Biography',
            validators: [hasLength({ max: 140 }, 'Try to keep the short biography under 140 characters', true)],
            required: true,
            type: 'text',
            placeholderText: 'Enter a one sentence version of the biography...',
            formHelper: authorShortBiography,
        },
        {
            id: 'author-noIndex',
            key: 'noIndex',
            name: 'No Index',
            required: false,
            type: 'checkbox',
            formHelper: articleIndexStatus(friendlyName),
        },
        {
            id: 'author-publishedDate',
            key: 'publishedDateUtc',
            name: 'Published',
            type: 'publishBox',
            formHelper: articlePublishedStatus(friendlyName),
        },
        {
            id: 'author-fullBioHeading',
            type: 'subHeading',
            text: 'Full Biography',
        },
        {
            id: 'author-fullBio',
            key: 'fullBiography',
            name: 'Biography',
            validators: [
                (value) =>
                    typeof value === 'string' && value.split(' ').length - 1 >= 60
                        ? { message: 'Try to keep the biography under 60 words', isWarning: true }
                        : null,
            ],
            required: false,
            type: 'text',
            placeholderText: 'Enter a biography describing the author...',
            formHelper: authorBiography,
        },
        {
            id: 'author-advancedInforHeading',
            type: 'subHeading',
            text: 'Advanced information',
        },
        {
            id: 'author-location',
            key: 'location',
            name: 'Relative location',
            type: 'text',
            required: false,
            helperText: 'Ontario, Canada',
            formHelper: relativeLocation,
        },
        {
            id: 'author-role',
            key: 'role',
            name: 'Current role',
            type: 'text',
            required: false,
            formHelper: currentRole,
        },
        {
            id: 'author-expertise',
            key: 'expertise',
            name: 'Relevant experience',
            required: false,
            type: 'listBox',
            formHelper: authorExpertise,
        },
        {
            id: 'author-education',
            key: 'education',
            name: 'Education',
            required: false,
            type: 'listBox',
            formHelper: authorEducation,
        },
        {
            id: 'author-accomplishments',
            key: 'accomplishments',
            name: 'Accomplishments',
            type: 'listBox',
            required: false,
            formHelper: authorAccomplishments,
        },
        {
            id: 'author-externalLinksHeading',
            type: 'subHeading',
            text: 'External links',
        },
        {
            id: 'author-publications',
            key: 'publications',
            name: 'publication',
            type: 'accordionBlock',
            chipTitle: 'Other publications',
            count: 10,
            usedFor: 'link',
            keyPropertyName1: 'publicationName',
            keyPropertyName2: 'publicationLink',
            formHelper: [accordionBlock(friendlyName, 'publications'), columnLinkText, authorPublicationsURL],
        },
        {
            id: 'author-linkedIn',
            key: 'linkedInLink',
            name: 'Linkedin',
            validators: [regexMatchesOrIsEmpty(regexPatterns.requireHttpHttps, regexErrorMessages.linkedInLink)],
            type: 'text',
            required: false,
            formHelper: authorSocial('Linkedin'),
        },
        {
            id: 'author-twitter',
            key: 'twitterLink',
            name: 'Twitter',
            validators: [regexMatchesOrIsEmpty(regexPatterns.requireHttpHttps, regexErrorMessages.twitterLink)],
            type: 'text',
            formHelper: authorSocial('Twitter'),
        },
    ],
};

export const authorModel: Partial<Author> = {
    language: 1,
    name: '',
    slug: '',
    image: '',
    imageAlt: '',
    linkedInLink: '',
    twitterLink: '',
    canonical: '',
    noIndex: false,
    status: 0,
    fullBiography: '',
    education: [],
    accomplishments: [],
    expertise: [],
    location: '',
    role: '',
    publications: [],
    redirectUrl: '',
};
