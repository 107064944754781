import React, { startTransition } from 'react';
import { Grid } from '@mui/material';
import { footerStyles as classes } from './styleObjects/Footer';
import { GridContainer } from './GeotabGridComponents';
import { FooterGeotabLogo } from '@icon/FooterGeotabLogo';
import { TopFooterSection } from './TopFooterSection';
import { getLanguageByCode, getLanguageUrlPrefixByCode } from '@helpers/languages';
import { CMSLink } from '@components/CMSLink';
import { NewTabIcon } from '@icon/NewTabIcon';
import { useAtom, useSetAtom } from 'jotai';
import { selectedLanguageAtom, isRegionSelectionDialogOpenAtom } from '@atoms/appSettings';
import { footerAtom, isFooterDisabledAtom } from '@atoms/navigation';
import { LazyLoadContainer, Text } from '@web-for-marketing/react-ui';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { FooterTopContent } from '@models/footer';
import { SelectedLanguage } from '@components/RegionSelection/SelectedLanguage';
import { Chatbox } from '@components/Chatbox';
import { interactionResponse } from '@helpers/performance';

declare const window: Window & {
    Osano: {
        cm: {
            showDrawer: (text: string) => void;
        };
    };
};

export function Footer(): JSX.Element | null {
    const [selectedLanguage] = useAtom(selectedLanguageAtom);
    const showChatbox = getLanguageByCode(selectedLanguage)?.enableChatbox || false;
    const { prefixStringWithBaseUrl } = useStaticValues();
    const [navigationFooter] = useAtom(footerAtom);
    const setIsRegionSelection = useSetAtom(isRegionSelectionDialogOpenAtom);
    const [isFooterDisabled] = useAtom(isFooterDisabledAtom);

    const showFooter = !isFooterDisabled;

    const getFormattedCopyrightText = (text: string): string => {
        return text && text.replace('{{year}}', new Date().getFullYear().toString());
    };

    const osanoPreferences = async (text: string): Promise<void> => {
        window.Osano.cm.showDrawer(text);
        await interactionResponse();
    };

    const topFooterMap = new Map<number, FooterTopContent[]>();

    function buildFooterMap(): void {
        navigationFooter?.topContent?.forEach((ele) => {
            if (topFooterMap.has(ele.column)) {
                const columnWiseArray = topFooterMap.get(ele.column);
                if (columnWiseArray) {
                    columnWiseArray.push(ele);
                    topFooterMap.set(ele.column, columnWiseArray);
                }
            } else {
                const localArray = [];
                localArray.push(ele);
                topFooterMap.set(ele.column, localArray);
            }
        });
    }

    if (showFooter && Array.isArray(navigationFooter?.topContent)) {
        buildFooterMap();
    }

    return showFooter ? (
        <footer css={classes.footer(navigationFooter?.backgroundColor)}>
            <GridContainer css={classes.pad}>
                <Grid container alignItems='flex-start' css={classes.footerContainer}>
                    <Grid item xs={12}>
                        <CMSLink
                            href={getLanguageUrlPrefixByCode(selectedLanguage)}
                            css={classes.textLink}
                            target='_blank'
                            title='Go to Homepage'
                            aria-label='Go to Geotab Homepage'
                            style={{ display: 'inline-block', minWidth: 0 }}
                            type='link'
                        >
                            <FooterGeotabLogo css={classes.footerLogo} />
                        </CMSLink>
                    </Grid>
                    {Array.isArray(navigationFooter?.topContent) ? (
                        <>
                            {[1, 2, 3, 4].map((column) => (
                                <TopFooterSection key={column + 1} sectionData={topFooterMap.get(column) || []} />
                            ))}
                        </>
                    ) : null}
                </Grid>
            </GridContainer>
            <GridContainer css={classes.footnotes}>
                <Grid container spacing={0} css={[classes.footerContainer, classes.noBorder]}>
                    <Grid container>
                        {navigationFooter?.showLanguageRegion ? (
                            <Grid css={classes.languageLink} container item md={12} justifyContent='flex-end'>
                                <button
                                    css={[classes.textLink, classes.regionLink, classes.bottomLinks]}
                                    onClick={() => {
                                        startTransition(() => {
                                            setIsRegionSelection(true);
                                        });
                                    }}
                                >
                                    {navigationFooter ? (
                                        <SelectedLanguage languageCode={selectedLanguage} displayLongForm={true} />
                                    ) : null}
                                </button>
                            </Grid>
                        ) : null}
                        <Grid container>
                            {navigationFooter ? (
                                <>
                                    <Grid item xs={12} md={9} css={[classes.centerAlignMobile, classes.orderFooter]}>
                                        <div>
                                            {Array.isArray(navigationFooter?.bottomContent)
                                                ? navigationFooter.bottomContent.map((link, index) => {
                                                    return 'type' in link ? (
                                                        <React.Fragment key={index}>
                                                            {'link' === link.type ? (
                                                                link.title !== 'Cookies' ? (
                                                                    <CMSLink
                                                                        css={[classes.textLink, classes.bottomLinks]}
                                                                        rel={link.rel}
                                                                        underline='none'
                                                                        target={
                                                                            link.isTargetToNewTab
                                                                                ? '_blank'
                                                                                : undefined
                                                                        }
                                                                        href={link.href}
                                                                        type='link'
                                                                    >
                                                                        {link.title}{' '}
                                                                        {link.isTargetToNewTab ? (
                                                                            <NewTabIcon />
                                                                        ) : null}
                                                                    </CMSLink>
                                                                ) : (
                                                                    <button
                                                                        css={[
                                                                            classes.textLink,
                                                                            classes.bottomLinks,
                                                                            classes.bottomButtonLink,
                                                                        ]}
                                                                        aria-label='Open cookies popup window'
                                                                        onClick={() => {
                                                                            osanoPreferences(
                                                                                'osano-cm-dom-info-dialog-open'
                                                                            ).catch((e) => console.error(e));
                                                                        }}
                                                                    >
                                                                        {link.title}
                                                                    </button>
                                                                )
                                                            ) : null}
                                                            <Text css={classes.separator}> | </Text>
                                                        </React.Fragment>
                                                    ) : null;
                                                })
                                                : null}
                                        </div>
                                        <Text css={classes.copyRightText}>
                                            {' '}
                                            {getFormattedCopyrightText(
                                                navigationFooter.copyRightText ||
                                                    'Copyright 2020. Geotab Inc. All Rights Reserved'
                                            )}
                                        </Text>
                                    </Grid>
                                    <LazyLoadContainer
                                        item
                                        xs={12}
                                        md={3}
                                        css={[classes.centerAlignMobile, classes.socialFlexBox]}
                                    >
                                        {Array.isArray(navigationFooter?.socialMediaLinks)
                                            ? navigationFooter.socialMediaLinks.map((socialMediaLink, index) => {
                                                return (
                                                    <CMSLink
                                                        key={`${JSON.stringify(socialMediaLink)}-${index}`}
                                                        rel='noopener noreferrer'
                                                        target='_blank'
                                                        title={socialMediaLink.title}
                                                        href={socialMediaLink.href}
                                                        css={classes.shareIcon}
                                                        type='link'
                                                    >
                                                        <img
                                                            src=''
                                                            data-src={prefixStringWithBaseUrl(
                                                                socialMediaLink.imagePath
                                                            )}
                                                            alt=''
                                                            css={classes.socilaMediaIcon}
                                                        />
                                                    </CMSLink>
                                                );
                                            })
                                            : null}
                                    </LazyLoadContainer>
                                </>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
                {showChatbox ? <Chatbox /> : null}
            </GridContainer>
        </footer>
    ) : null;
}
