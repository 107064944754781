import { getRgbaColor, breakpoints, v2Colors } from '@web-for-marketing/react-core';
import { typographyStyles, formControlFontSizeStyles } from '@web-for-marketing/react-ui';
import { createTheme } from '@mui/material/styles';

export const appTheme = createTheme({
    breakpoints: {
        values: {
            xs: breakpoints.xs,
            sm: breakpoints.sm,
            md: breakpoints.md,
            lg: breakpoints.lg,
            xl: breakpoints.xl,
        },
    },
    palette: {
        primary: {
            main: v2Colors.core.geotab,
        },
        secondary: {
            main: v2Colors.core.innovation,
        },
        text: {
            primary: v2Colors.core.slate,
            secondary: v2Colors.core.silver,
        },
        background: {
            default: v2Colors.core.snow,
        },
    },
    typography: {
        body1: {
            ...typographyStyles.body1,
            color: v2Colors.core.slate,
        },
        body2: {
            ...typographyStyles.body2,
            color: v2Colors.core.slate,
        },
        subtitle1: {
            ...typographyStyles.header4,
            color: v2Colors.core.slate,
        },
        subtitle2: {
            ...typographyStyles.header3,
            color: v2Colors.core.slate,
            fontWeight: 400,
        },
        caption: {
            fontSize: '1.4rem',
            color: v2Colors.core.slate,
            fontWeight: 400,
        },
    },
    components: {
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: v2Colors.core.slate,
                },
            },
        },
        MuiCollapse: {
            styleOverrides: {
                entered: {
                    zIndex: 9999,
                    transform: 'scaleY(1) translateY(0)',
                    opacity: 1,
                    visibility: 'visible',
                    [`@media (max-width: ${breakpoints.sm}px)`]: {
                        transform: 'scaleY(1) translateY(0)',
                    },
                    borderTop: `0.1rem solid ${getRgbaColor(v2Colors.core.slate, 0.1)}`,
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    height: 'unset',
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    height: '4rem',
                    width: '4rem',
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '2.4rem',
                },
                colorAction: {
                    fill: v2Colors.core.slate,
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    display: 'flex',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                outlined: {
                    ...formControlFontSizeStyles,
                },
                root: {
                    ...formControlFontSizeStyles,
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    height: '5rem',
                    width: '5rem',
                    color: v2Colors.core.innovation,
                    backgroundColor: v2Colors.core.snow,
                    transition: 'color ease .5s',
                    textTransform: 'none',
                    '&:hover': {
                        color: `${v2Colors.core.geotab} !important`,
                        backgroundColor: v2Colors.core.snow,
                        transition: 'color ease .5s',
                    },
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                container: {
                    alignItems: 'stretch',
                },
                item: {
                    width: 'auto',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1.8rem',
                    textTransform: 'none',
                    minWidth: 'unset',
                    letterSpacing: '.05rem',
                    textDecoration: 'none',
                    minHeight: '5rem',
                    boxShadow: 'none !important',
                    lineHeight: 1.4,
                    fontWeight: 500,
                    borderRadius: '0.6rem',
                    transition: 'background-color 500ms ease, border-color 500ms ease, color 500ms ease',
                    [`@media (min-width: ${breakpoints.xl}px)`]: {
                        fontSize: '2rem',
                    },
                    [`@media (max-width: ${breakpoints.lg}px)`]: {
                        fontSize: '1.8rem',
                    },
                    [`@media (max-width: ${breakpoints.sm}px)`]: {
                        fontSize: '1.6rem',
                        width: '100%',
                        padding: '1.7rem 0 1.9rem',
                        textAlign: 'center',
                    },
                    '& .MuiTouchRipple-root': {
                        '@media (prefers-reduced-motion: reduce)': {
                            display: 'none !important',
                        },
                    },
                    '&.Mui-disabled': {
                        color: v2Colors.core.slate,
                        border: `1px solid ${v2Colors.core.smoke}`,
                        backgroundColor: v2Colors.core.smoke,
                    },
                },
                contained: {
                    padding: '1.6rem 2.6rem  !important',
                    backgroundColor: v2Colors.core.innovation,
                    color: v2Colors.core.snow,
                    border: '0.1rem solid',
                    borderColor: v2Colors.core.innovation,
                    '&:hover': {
                        borderColor: v2Colors.core.innovation,
                        color: v2Colors.core.innovation,
                        backgroundColor: v2Colors.core.snow,
                    },
                    '@media (prefers-reduced-motion: reduce)': {
                        '&:focus': {
                            borderColor: v2Colors.core.innovation,
                            color: v2Colors.core.innovation,
                            backgroundColor: v2Colors.core.snow,
                        },
                    },
                    [`@media (max-width: ${breakpoints.md}px)`]: {
                        textAlign: 'center',
                    },
                },
                containedPrimary: {
                    padding: '1.6rem 2.6rem  !important',
                    backgroundColor: v2Colors.core.innovation,
                    color: v2Colors.core.snow,
                    border: '0.1rem solid',
                    borderColor: v2Colors.core.innovation,
                    '&:hover': {
                        borderColor: v2Colors.core.innovation,
                        color: v2Colors.core.innovation,
                        backgroundColor: v2Colors.core.snow,
                    },
                    '@media (prefers-reduced-motion: reduce)': {
                        '&:focus': {
                            borderColor: v2Colors.core.innovation,
                            color: v2Colors.core.innovation,
                            backgroundColor: v2Colors.core.snow,
                        },
                    },
                    [`@media (max-width: ${breakpoints.md}px)`]: {
                        textAlign: 'center',
                    },
                },
                containedSecondary: {
                    padding: '1.6rem 2.6rem  !important',
                    backgroundColor: v2Colors.core.snow,
                    color: v2Colors.core.innovation,
                    border: '0.1rem solid',
                    borderColor: v2Colors.core.snow,
                    '&:hover': {
                        borderColor: v2Colors.core.snow,
                        color: v2Colors.core.snow,
                        backgroundColor: v2Colors.core.innovation,
                    },
                    '@media (prefers-reduced-motion: reduce)': {
                        '&:focus': {
                            borderColor: v2Colors.core.snow,
                            color: v2Colors.core.snow,
                            backgroundColor: v2Colors.core.innovation,
                        },
                    },
                    [`@media (max-width: ${breakpoints.md}px)`]: {
                        textAlign: 'center',
                    },
                },
                outlined: {
                    padding: '1.6rem 2.6rem  !important',
                    backgroundColor: v2Colors.core.snow,
                    color: v2Colors.core.innovation,
                    border: '0.1rem solid',
                    borderColor: v2Colors.core.innovation,
                    '&:hover': {
                        color: v2Colors.core.snow,
                        borderColor: v2Colors.core.innovation,
                        backgroundColor: v2Colors.core.innovation,
                    },
                    '@media (prefers-reduced-motion: reduce)': {
                        '&:focus': {
                            color: v2Colors.core.snow,
                            borderColor: v2Colors.core.innovation,
                            backgroundColor: v2Colors.core.innovation,
                        },
                    },
                    [`@media (max-width: ${breakpoints.md}px)`]: {
                        textAlign: 'center',
                    },
                },
                outlinedSecondary: {
                    padding: '1.6rem 2.6rem  !important',
                    backgroundColor: getRgbaColor(v2Colors.core.innovation, 0.3),
                    color: v2Colors.core.snow,
                    border: '0.1rem solid',
                    borderColor: v2Colors.core.snow,
                    '&:hover': {
                        backgroundColor: v2Colors.core.innovation,
                        borderColor: v2Colors.core.snow,
                    },
                    '@media (prefers-reduced-motion: reduce)': {
                        '&:focus': {
                            backgroundColor: v2Colors.core.innovation,
                            borderColor: v2Colors.core.snow,
                        },
                    },
                    [`@media (max-width: ${breakpoints.md}px)`]: {
                        textAlign: 'center',
                    },
                },
                text: {
                    color: v2Colors.core.innovation,
                    display: 'inline-block',
                    padding: '0.6rem 0.8rem !important',
                    transform: 'translateX(-0.6rem)',
                    background: 'none !important',
                    whiteSpace: 'initial',
                    '& > *': {
                        whiteSpace: 'initial',
                    },
                    '&:hover': {
                        color: v2Colors.core.innovationDark,
                        textDecoration: 'underline',
                    },
                    [`@media (max-width: ${breakpoints.sm}px)`]: {
                        width: 'unset',
                    },
                    '@media (prefers-reduced-motion: reduce)': {
                        '&:focus': {
                            color: v2Colors.core.innovationDark,
                            outline: `1px solid ${v2Colors.core.innovationDark}`,
                        },
                        '&:active': {
                            color: v2Colors.core.innovationDark,
                            outline: 'none',
                        },
                    },
                },
                textSecondary: {
                    color: v2Colors.core.snow,
                    display: 'inline-block',
                    padding: '0.6rem 0.8rem !important',
                    transform: 'translateX(-0.6rem)',
                    backgroundColor: 'unset !important',
                    border: `0.1rem solid ${getRgbaColor(v2Colors.core.snow, 0)}`,
                    '&:hover': {
                        color: v2Colors.core.snow,
                        borderColor: v2Colors.core.snow,
                        backgroundColor: `${getRgbaColor(v2Colors.core.innovation, 0.3)} !important`,
                    },
                    [`@media (max-width: ${breakpoints.sm}px)`]: {
                        width: 'unset',
                    },
                    '@media (prefers-reduced-motion: reduce)': {
                        '&:focus': {
                            color: v2Colors.core.snow,
                            borderColor: v2Colors.core.snow,
                            backgroundColor: `${getRgbaColor(v2Colors.core.innovation, 0.3)} !important`,
                        },
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '1.4rem',
                    fontWeight: 400,
                    lineHeight: '1.5 !important',
                },
            },
        },
        MuiList: {
            styleOverrides: {
                padding: {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    [`@media (max-width: ${breakpoints.sm}px)`]: {
                        margin: '0',
                    },
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    marginTop: '-1rem',
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                },
                button: {
                    '&:hover': {
                        backgroundColor: v2Colors.core.cloud,
                    },
                    '&:focus': {
                        backgroundColor: v2Colors.core.cloud,
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    zIndex: '400 !important' as unknown as number,
                },
                elevation24: {
                    boxShadow: `0px 0px 15px ${getRgbaColor(v2Colors.core.deepSpace, 0.5)}`,
                    borderRadius: '10px',
                },
            },
        },
        MuiMobileStepper: {
            styleOverrides: {
                dotActive: {
                    backgroundColor: v2Colors.core.innovation,
                },
                dot: {
                    backgroundColor: v2Colors.core.cloud,
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                input: {
                    fontSize: '1.2rem',
                },
            },
        },
        MuiStep: {
            styleOverrides: {
                root: {
                    flex: 'unset !important',
                    minWidth: '20rem',
                    paddingLeft: '2% !important',
                    paddingRight: '2% !important',
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    fill: v2Colors.core.snow,
                    border: `2px ${v2Colors.core.smoke} solid`,
                    borderRadius: '100%',
                    height: '3.8rem',
                    width: '3.8rem',
                    '&.Mui-active': {
                        borderColor: v2Colors.core.innovation,
                        '& text': {
                            fill: v2Colors.core.geotab,
                        },
                    },
                    text: {
                        fill: v2Colors.core.smoke,
                        fontWeight: 500,
                        fontSize: '0.5em',
                    },
                },
            },
        },
        MuiStepConnector: {
            styleOverrides: {
                root: {
                    display: 'none',
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    fontSize: '1.7rem',
                    letterSpacing: 0,
                    fontWeight: 500,
                    color: v2Colors.core.smoke,
                    marginTop: '2rem',
                },
                root: {
                    '&.Mui-active': {
                        color: `${v2Colors.core.geotab} !important`,
                    },
                },
            },
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    justifyContent: 'center',
                    width: '100%',
                    position: 'relative',
                    '&::after': {
                        content: '""',
                        width: '96%',
                        position: 'absolute',
                        backgroundColor: v2Colors.core.smoke,
                        height: '2px',
                        top: '25%',
                        left: '2%',
                        zIndex: '-1',
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    borderColor: `${v2Colors.core.geotab} !important`,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: getRgbaColor(v2Colors.core.deepSpace, 0.03),
                    },
                    '&:focus': {
                        backgroundColor: getRgbaColor(v2Colors.core.deepSpace, 0.1),
                    },
                },
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                anchorOriginTopCenter: {
                    top: '0 !important',
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    ...formControlFontSizeStyles,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: 'inherit',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '1.4rem',
                    [`@media (min-width: ${breakpoints.xl}px)`]: {
                        fontSize: '1.6rem',
                    },
                    [`@media (max-width: ${breakpoints.sm}px)`]: {
                        fontSize: '1.2rem',
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '1.5rem !important',
                    padding: '1.5rem',
                },
            },
        },
    },
});
