import { atom } from 'jotai';
import { defaultNavbar, defaultFooter } from '@components/EditorEntity/navigationGalleryContent';
import { NullableFooterNavigation } from '@models/footer';
import { NullableMenuNavigation, NullableMenuNavigationV2 } from '@models/navigationBar';

export const navbarAtom = atom<NullableMenuNavigation | NullableMenuNavigationV2>(defaultNavbar);

export const isNavbarDisabledAtom = atom<boolean>(false);

export const footerAtom = atom<NullableFooterNavigation>(defaultFooter);

export const isFooterDisabledAtom = atom<boolean>(false);

export const selectedNavbarDropdownAtom = atom('');

export const mobileSelectedNavbarDropdownAtom = atom<number | null>(null);

export const isInnerMenuOpenAtom = atom((get) => get(mobileSelectedNavbarDropdownAtom) !== null);
