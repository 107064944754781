import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { PageContainer } from '@components/PageContainer';
import { AccessibleNav } from '@components/NavigationBarComponents/AccessibleNav';
import { RegionSelectionDialog } from '@components/RegionSelection/RegionSelectionDialog';
import { PageBreadcrumb } from '@components/PageBreadcrumb';
import { Footer } from '@components/Footer';
import { notFoundAtom } from '@atoms/appSettings';
import { useAtom } from 'jotai';
import { ErrorPage } from '@components/ErrorPage';

export function PublicLayout(): JSX.Element {
    const [notFound] = useAtom(notFoundAtom);
    const [routeChangeCount, setRouteChangeCount] = useState(0);
    // 'notFound' initial data can still be true on a valid route accessed with client side navigation
    // This ensures we no longer use the 'notFound' initial data value to show the NotFound component
    const hasRouteChanged = routeChangeCount > 1;
    const { pathname } = useLocation();

    useEffect(() => {
        setRouteChangeCount((val) => val + 1);
    }, [pathname]);

    return (
        <>
            <AccessibleNav />
            <PageBreadcrumb />
            <PageContainer>{notFound && !hasRouteChanged ? <ErrorPage /> : <Outlet />}</PageContainer>
            <RegionSelectionDialog />
            <Footer />
        </>
    );
}
