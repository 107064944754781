import { v2Colors, breakpoints } from '@web-for-marketing/react-core';

export const RegionSelectionDialogStyles = {
    contentContainer: {
        padding: '4rem 7rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            paddingTop: '1.6rem',
        },
    },
    mobileButtonsContainer: {
        width: '100%',
    },
    selectRegionsDropdown: {
        marginBottom: '1.6rem',
    },
    continueButton: {
        width: '100%',
        marginBottom: '1.5rem',
    },
    closeIcon: {
        color: v2Colors.text.secondary,
    },
    closeButton: {
        marginRight: '2rem',
        marginTop: '2rem',
    },
    iconLanguageList: {
        width: '13rem',
        height: '13rem',
        marginBottom: '2rem',
    },
    iconLanguageListMobile: {
        width: '10rem',
        height: '10rem',
        marginBottom: '1rem',
    },
    dialogHeading: {
        color: v2Colors.core.geotab,
        fontWeight: 'bold',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            fontWeight: 'normal',
        },
    },
} as const;
