import { regexErrorMessages, regexPatterns } from './regexHelper';
import {
    blogEntityDescription,
    blogEntityTitle,
    blogEntityName,
    articleHiddenTag,
    articleIndexStatus,
    articlePublishedStatus,
    articleCanonical,
    articleSlug,
    articleLanguage,
    entityRedirectUrl,
    slugEntityBreadcrumbDisplayName,
} from '@helpers/AccessibleFormHelperConstants';
import { BlogFormHelper } from '@models/blogForm';
import { Tag } from '@models/tag';
import { EntityName } from '@models/entity';
import { regexMatchesOrIsEmpty } from '@helpers/validators';
import { breadcrumbDisplayNameValidator } from './validationRules';

const friendlyName = 'Tag';
export const tagFormHelper: BlogFormHelper = {
    name: 'Tag',
    entityName: EntityName.Tag,
    apiControllerName: 'tag',
    hasMediaField: false,
    fields: [
        {
            id: 'tag-language',
            key: 'language',
            name: 'Language',
            required: true,
            type: 'select',
            list: 'languages',
            listItemValueKey: 'id',
            readOnlyModes: ['edit'],
            formHelper: articleLanguage(friendlyName),
        },
        {
            id: 'tag-name',
            key: 'name',
            name: 'Tag Name',
            required: true,
            maxLength: 100,
            type: 'text',
            formHelper: blogEntityName(friendlyName),
        },
        {
            id: 'tag-title',
            key: 'title',
            name: 'SEO Title',
            required: true,
            type: 'text',
            formHelper: blogEntityTitle(friendlyName),
        },
        {
            id: 'tag-description',
            key: 'description',
            name: 'SEO Description',
            required: true,
            type: 'text',
            formHelper: blogEntityDescription(friendlyName),
        },
        {
            id: 'tag-slug',
            key: 'slug',
            name: 'Url Slug',
            validators: [regexMatchesOrIsEmpty(regexPatterns.slug, regexErrorMessages.slug)],
            required: true,
            maxLength: 100,
            type: 'text',
            sanitizationConfiguration: {
                autoTrim: true,
                lowercaseOnChange: true,
            },
            formHelper: articleSlug(friendlyName),
        },
        {
            id: 'tag-breadcrumbDisplayName',
            key: 'breadcrumbDisplayName',
            name: 'Breadcrumb Display Name',
            validators: [breadcrumbDisplayNameValidator],
            required: false,
            type: 'text',
            formHelper: slugEntityBreadcrumbDisplayName(friendlyName),
        },
        {
            id: 'tag-canonical',
            key: 'canonical',
            name: 'Canonical Url',
            required: false,
            type: 'text',
            sanitizationConfiguration: {
                autoTrim: true,
            },
            formHelper: articleCanonical(friendlyName),
        },
        {
            id: 'tag-redirectUrl',
            key: 'redirectUrl',
            name: 'Redirect Url',
            required: false,
            maxLength: 5000,
            type: 'text',
            sanitizationConfiguration: {
                autoTrim: true,
            },
            formHelper: entityRedirectUrl(friendlyName),
        },
        {
            id: 'tag-hidden',
            key: 'hidden',
            name: 'Hide tag on articles',
            required: false,
            type: 'checkbox',
            formHelper: articleHiddenTag,
        },
        {
            id: 'tag-noIndex',
            key: 'noIndex',
            name: 'No Index',
            required: false,
            type: 'checkbox',
            formHelper: articleIndexStatus(friendlyName),
        },
        {
            id: 'tag-publishedDate',
            key: 'publishedDateUtc',
            name: 'Published',
            type: 'publishBox',
            formHelper: articlePublishedStatus(friendlyName),
        },
    ],
};

export const tagModel: Partial<Tag> = {
    language: 1,
    name: '',
    slug: '',
    canonical: '',
    noIndex: false,
    status: 0,
    publishedDateUtc: '',
};
