import React, { useState, useMemo } from 'react';
import { Grid } from '@mui/material';
import { SearchIcon } from '@web-for-marketing/react-ui';
import { v2Colors } from '@web-for-marketing/react-core';
import { useAtom } from 'jotai';
import { selectedLanguageAtom } from '@atoms/appSettings';
import { getLanguageRouteAndPrefixByCodeAndRouteKey } from '@helpers/languages';
import { keyIsEnter } from '@helpers/keyPressEventHelper';
import { useTranslation } from '@stateManagement/TranslationContext';
import { getTranslation } from '@helpers/languageTranslations';
import { Input } from '@web-for-marketing/react-ui';

const classes = {
    searchBar: {
        display: 'flex',
    },
    searchButton: {
        width: '6rem',
        backgroundColor: v2Colors.core.geotab,
        borderRadius: '0 5px 5px 0',
        border: `1px solid ${v2Colors.core.geotab}`,
        cursor: 'pointer',
        transition: 'background-color 300ms ease, border-color 300ms ease, border 300ms ease',
        '&:hover': {
            backgroundColor: v2Colors.core.snow,
            '& > svg path': {
                fill: v2Colors.core.geotab,
            },
        },
        '&:focus': {
            outline: 'none',
            backgroundColor: v2Colors.core.innovation,
            border: `1px solid ${v2Colors.core.innovation} !important`,
            '& > svg path': {
                fill: `${v2Colors.core.snow} !important`,
            },
        },
    },
    disabledButton: {
        color: v2Colors.core.slate,
        border: '1px solid #dee0e3 !important',
        backgroundColor: '#dee0e3 !important',
        '& > svg path': {
            fill: `${v2Colors.core.snow} !important`,
        },
        '&:focus': {
            outline: 'none',
            border: '1px solid #dee0e3 !important',
            backgroundColor: '#dee0e3 !important',
        },
    },
    searchIcon: {
        '& path': {
            fill: v2Colors.core.snow,
        },
        width: '2.8rem',
        height: '2.8rem',
    },
    searchInput: {
        borderRadius: '5px 0 0 5px',
        backgroundColor: v2Colors.core.snow,
    },
    searchTextBox: {
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
        '& .MuiFormControl-root': {
            borderRadius: '5px 0 0 5px',
        },
    },
};

interface PublicSearchBarProps {
    searchResult?: string;
}

export function PublicSearchBar({ searchResult }: PublicSearchBarProps): JSX.Element {
    const [searchTerm, setSearchTerm] = useState(searchResult || '');
    const buttonDisabled = !searchTerm.length;
    const [selectedLanguage] = useAtom(selectedLanguageAtom);
    const { selectedLanguageTranslation } = useTranslation();
    const searchTranslation = useMemo(
        () => getTranslation(selectedLanguageTranslation, 'search'),
        [selectedLanguageTranslation]
    );

    function handleSearch(): void {
        if (!buttonDisabled) {
            window.location.href = `${getLanguageRouteAndPrefixByCodeAndRouteKey(
                selectedLanguage,
                'publicSearchUrl'
            )}?query=${searchTerm}`;
        }
    }

    function handleKeyDown(event: React.KeyboardEvent): void {
        keyIsEnter(event) && handleSearch();
    }

    return (
        <div css={classes.searchBar}>
            <Input
                id='search'
                label={searchTranslation.search}
                hiddenLabel
                placeholder={searchTranslation.searchBarPlaceholder}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleKeyDown}
                css={classes.searchTextBox}
            />
            <Grid
                container
                alignItems='center'
                justifyContent='center'
                component='button'
                css={[classes.searchButton, buttonDisabled ? classes.disabledButton : '']}
                onClick={handleSearch}
                aria-label={`search for ${searchTerm}`}
            >
                <SearchIcon css={classes.searchIcon} />
            </Grid>
        </div>
    );
}
